import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'

import SEO from 'components/seo'
import { getHomeSlug } from 'utils'

import styles from './404.module.less'

const Page404 = ({ intl, pageContext }) => {
  const homeSlug = getHomeSlug(pageContext)

  return (
    <>
      <SEO
        locale={pageContext.locale}
        title={intl.formatMessage({ id: '404.title' })}
      />
      <section className={styles.page404}>
        <h1>404</h1>
        <h3>
          <FormattedMessage id="404.tip" />
        </h3>
        <Link to={homeSlug}>
          <Button type="primary" ghost>
            <FormattedMessage id="404.btn" />
          </Button>
        </Link>
      </section>
    </>
  )
}

export default injectIntl(Page404)
